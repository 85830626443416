import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ReCaptchaV3FormGroup from './ReCaptchaV3FormGroup';

const CaptchaFormGroup = forwardRef(({ captchaId, ...attributes }, fieldRef) => {
  const captchaSettings = useSelector(({ settings }) => settings.captcha);

  if (!captchaSettings)
    return null;

  return <ReCaptchaV3FormGroup captchaId={captchaId} {...attributes} />;
});

CaptchaFormGroup.propTypes = {
  captchaId: PropTypes.string.isRequired,
};

export default CaptchaFormGroup;