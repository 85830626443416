import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const ReCaptchaV3FormGroup = ({
  fieldName = 'captchaToken',
  captchaId,
}) => {
  const { setFieldValue } = useFormikContext();

  const executeRecaptcha = useGoogleReCaptcha().executeRecaptcha;
  const syncObj = useSelector(({ captcha }) => captcha[captchaId]);

  useEffect(() => {
    let isUnmounted = false;
    (async () => {
      if (!executeRecaptcha)
        return;
      const token = await executeRecaptcha('validate_reCaptcha');
      if (!isUnmounted)
        setFieldValue(fieldName, token);
    })();

    return () => isUnmounted = true;
  }, [executeRecaptcha, syncObj]);

  return null;
};

ReCaptchaV3FormGroup.propTypes = {
  fieldName: PropTypes.string,
  captchaId: PropTypes.string,
};

export default ReCaptchaV3FormGroup;
